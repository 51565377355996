import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers/index";
import * as PDFFunctions from "../actions/pdf";
import { PDFState } from "../reducers/pdfReducer";

const mapStateToProps = (state: RootReducerProps, ownProps: any): PDFState => ({
	...ownProps,
	agreement: state.pdf.agreement,
    keyTermGroups: state.pdf.keyTermGroups,
    keyTermList: state.pdf.keyTermList,
	keyTerms: state.pdf.keyTerms,
	notes: state.pdf.notes,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(PDFFunctions, dispatch);

const PDFContainer = (component: any) => {
	return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default PDFContainer;

import {
  SET_AGREEMENT,
  SET_KEY_TERM_GROUP,
  SET_KEY_TERM_ITEMS,
  SET_AGREEMENT_KEY_TERMS,
  SET_NOTES,
} from "../definitions/pdfConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { ActionProps } from "../../shared/types/action.type";
import { KeyTermGroups } from "../../models/KeyTerms/keyTermGroups.model";
import { Agreement } from "../../models/Agreements/agreement.model";
import { KeyTermList } from "../../services/AgreementService/agreementKeyTerm.service";
import { AgreementKeyTerm } from "../../models/KeyTerms/agreementKeyTerms.model";
import { Note } from "../../views/Document/PDFPin";

export interface PDFState {
  keyTermGroups?: KeyTermGroups[];
  agreement?: Agreement;
  keyTermList?: KeyTermList;
  keyTerms?: AgreementKeyTerm[];
  notes?: Note[];
}

export interface PDFAction extends ActionProps {
  payload: PDFState;
}

export interface PDFReducerProps extends PDFState {
  setKeyTermGroups: (keyTermGroups?: KeyTermGroups[]) => void;
  setAgreement: (agreement?: Agreement) => void;
  setKeyTermList: (keyTermList?: KeyTermList) => void;
  setKeyTerms: (keyTerms: AgreementKeyTerm[]) => void;
  setNotes: (notes: Note[]) => void;
}

const initState: PDFState = {
  keyTermGroups: [],
  agreement: undefined,
  keyTermList: {},
  keyTerms: [],
  notes: [],
};

const pdfReducer = CreateReducer(initState, {
  [SET_AGREEMENT](state: PDFState, action: PDFAction): PDFState {
    const { agreement } = action?.payload;
    return {
      ...state,
      agreement,
    };
  },
  [SET_KEY_TERM_GROUP](state: PDFState, action: PDFAction): PDFState {
    const { keyTermGroups } = action?.payload;
    return {
      ...state,
      keyTermGroups,
    };
  },
  [SET_KEY_TERM_ITEMS](state: PDFState, action: PDFAction): PDFState {
    const { keyTermList } = action?.payload;
    return {
      ...state,
      keyTermList,
    };
  },
  [SET_AGREEMENT_KEY_TERMS](state: PDFState, action: PDFAction): PDFState {
    const { keyTerms } = action?.payload;
    return {
      ...state,
      keyTerms,
    };
  },
  [SET_NOTES](state: PDFState, action: PDFAction): PDFState {
    const { notes } = action?.payload;
    return {
      ...state,
      notes,
    };
  },
});

export default pdfReducer;

import { HL_AGREEMENTS,HL_AGREEMENTS_AMENDMENT,HL_AGREEMENT_UPDATE_OUTLINED_STATUS} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { generatePath } from "react-router";
import axiosInstance from "../../interceptor/axiosInstance";
import { Agreement, Amendment } from "../../models/Agreements/agreement.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Pagination } from "../../models/Pagination/pagination.model";

export class HLAgreementService {
  static fetchAgreements(
    params: {
      search_text?: string;
      fund_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      dates?: string[];
      tiers?: string[];
      agreement_status_ids?: string[];
      progress_percents?: string[];
      sitting_with?: string[];
      is_complete?: boolean;
      order_by?: string;
      order_direction?: string;
      page?: number
    },
    onSuccess: (agreements: Agreement[], meta: Pagination) => void,
    onError: Function,
    onFinal: () => void,
    isAdmin: boolean
  ) {
    const API_URL = HL_AGREEMENTS
    // .replace("hl", "hf");
    !params.search_text && delete params.search_text
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreements"]) as Agreement[];
        const pagination = deserialize(Pagination, response.data["meta"]);
        onSuccess(data, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static getAgreement(
    agreementId: any,
    onSuccess: (agreement: Agreement) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HL_AGREEMENTS + "/" + agreementId);
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreement"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateOutlinedStatus(
    agreementId: string,
    agreementKeyId: string,
    status: boolean,
    onSuccess: () => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HL_AGREEMENT_UPDATE_OUTLINED_STATUS, {
      agreementId,
      agreementKeyId,
    });
    axiosInstance
      .put(API_URL, { outlier_status: status })
      .then((response) => {
        onSuccess();
      })
      .catch((ex) => {
           Notification({
             message: "Unable to update status",
             description: (ex as Error)?.message ,
             type: NotificationTypes?.ERROR,
           });
        onError(ex);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAmendments(
    agreementId: string,
    params: {
      is_archived: boolean;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Amendment, response.data["amendments"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

}

import { Button } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import * as React from "react";
import { FC } from "react";
import "./buttonComponent.scss";
type DisplayType = "space-between" | "default";
interface ButtonComponentProps {
  children?: any;
  type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed";
  htmlType?: "button" | "submit" | "reset";
  onClick?: React.MouseEventHandler<HTMLElement>;
  loading?: boolean;
  icon?: any;
  iconRight?: any;
  disabled?: boolean;
  className?: string;
  displayType?: DisplayType;
  danger?: boolean,
  size?: SizeType,
  ref?: any
}

const ButtonComponent: FC<ButtonComponentProps> = (props) => {
  const {
    type,
    htmlType,
    onClick,
    children,
    loading,
    icon,
    iconRight,
    disabled,
    className,
    displayType,
    danger = false,
    size,
    ref
  } = props;
  const content = () => {
    if (iconRight && children) {
      return (
        <React.Fragment>
          <span>{children}</span>
          {iconRight}
        </React.Fragment>
      );
    }
    return children;
  };
  return (
    <div className="button-component" ref={ref}>
      <Button
        type={type}
        danger={danger}
        htmlType={htmlType}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
        className={`${className} ${displayType == "space-between" && "btn__flex"}`}
        icon={icon}
        size={size ? size : "middle"}
      >
        {content()}
      </Button>
    </div>
  );
};

export default ButtonComponent;

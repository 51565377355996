import React, { FC, useState } from "react";
import { MinimalButton } from "@react-pdf-viewer/core";
import {
  NextIcon,
  PreviousIcon,
  RenderSearchProps,
  SearchPlugin,
} from "@react-pdf-viewer/search";
import "./pDFSearch.scss";
import { Col, Row } from "antd";

interface PDFSearchProps {
  searchPluginInstance: SearchPlugin;
}

const PDFSearch: FC<PDFSearchProps> = (props) => {
  const { searchPluginInstance } = props;

  const { Search } = searchPluginInstance;

  const [readyToSearch, setReadyToSearch] = useState(false);

  const goToPage = (pageNo: number) => {
    const pages = Object.values(
      document.getElementsByClassName("rpv-core__inner-page")
    );
    for (let page of pages) {
      const pageValue = page.getAttribute("aria-label");
      if (`Page ${pageNo + 1}` === pageValue) {
        const wrapper = document.getElementById("pdf__wrapper");
        const { x, y } = page.getBoundingClientRect();
        window?.scrollTo({
          top: y,
          left: x,
        });
      }
    }
  };

  return (
    <div className="p-d-f-search">
      <Search>
        {(renderSearchProps: RenderSearchProps) => {
          return (
            <>
              <input
                placeholder="Enter to search"
                type="text"
                value={renderSearchProps.keyword}
                onChange={(e) => {
                  setReadyToSearch(false);
                  renderSearchProps.setKeyword(e.target.value);
                  
                }}
                onKeyDown={async (e) => {
                  if (e.keyCode === 13 && renderSearchProps.keyword) {
                    setReadyToSearch(true);
                    const results = await renderSearchProps.search();
                    if (results?.length) {
                      goToPage(results[0]?.pageIndex);
                    }
                  }
                }}
              />
              {readyToSearch &&
                renderSearchProps.keyword &&
                renderSearchProps.numberOfMatches === 0 && (
                  <div style={{ padding: "0 8px" }}>Not found</div>
                )}
              {readyToSearch &&
                renderSearchProps.keyword &&
                renderSearchProps.numberOfMatches > 0 && (
                  <div className="page-display">
                    {renderSearchProps.currentMatch} of {renderSearchProps.numberOfMatches}
                  </div>
                  // <Row>
                  //   <Col>{renderSearchProps.currentMatch} of </Col>
                  //   <Col></Col>
                  // </Row>
                )}
              <div className="tool__container">
                <MinimalButton onClick={renderSearchProps.jumpToPreviousMatch}>
                  <PreviousIcon />
                </MinimalButton>
                <MinimalButton onClick={renderSearchProps.jumpToNextMatch}>
                  <NextIcon />
                </MinimalButton>
              </div>
            </>
          );
        }}
      </Search>
    </div>
  );
};

export default PDFSearch;

// Matches

// <Tooltip
// position={Position.BottomCenter}
// target={
//   <button
//     style={{
//       background: "#fff",
//       border: "none",
//       borderBottom: `2px solid ${
//         renderSearchProps.matchCase ? "blue" : "transparent"
//       }`,
//       height: "100%",
//       padding: "0 2px",
//     }}
//     onClick={() =>
//       renderSearchProps.changeMatchCase(
//         !renderSearchProps.matchCase
//       )
//     }
//   >
//     <Icon>
//       <path d="M15.979,21.725,9.453,2.612a.5.5,0,0,0-.946,0L2,21.725" />
//       <path d="M4.383 14.725L13.59 14.725" />
//       <path d="M0.5 21.725L3.52 21.725" />
//       <path d="M14.479 21.725L17.5 21.725" />
//       <path d="M22.5,21.725,18.377,9.647a.5.5,0,0,0-.946,0l-1.888,5.543" />
//       <path d="M16.92 16.725L20.794 16.725" />
//       <path d="M21.516 21.725L23.5 21.725" />
//     </Icon>
//   </button>
// }
// content={() => "Match case"}
// offset={{ left: 0, top: 8 }}
// />
// <Tooltip
// position={Position.BottomCenter}
// target={
//   <button
//     style={{
//       background: "#fff",
//       border: "none",
//       borderBottom: `2px solid ${
//         renderSearchProps.wholeWords ? "blue" : "transparent"
//       }`,
//       height: "100%",
//       padding: "0 2px",
//     }}
//     onClick={() =>
//       renderSearchProps.changeWholeWords(
//         !renderSearchProps.wholeWords
//       )
//     }
//   >
//     <Icon>
//       <path d="M0.500 7.498 L23.500 7.498 L23.500 16.498 L0.500 16.498 Z" />
//       <path d="M3.5 9.498L3.5 14.498" />
//     </Icon>
//   </button>
// }
// content={() => "Match whole word"}
// offset={{ left: 0, top: 8 }}
// />

//  will be governed by

// have entered and/or anticipate entering into one or more transactions (each a “Transaction”) that are or will be governed

// <span class="rpv-core__text-layer-text" style="left: 302.556px; top: 261.68px; font-size: 41.724px; font-family: serif; transform: scaleX(1.04632);">have entered and/or anticipate entering into one or more transactions (each a “Transaction”) that are or will be governed</span>
// <span class="rpv-core__text-layer-text" style="left: 302.556px; top: 261.68px; font-size: 41.724px; font-family: serif; transform: scaleX(1.04632);">by this 2002 Master Agreement, which includes the schedule (the “Schedule”), and the documents and other confirming</span>

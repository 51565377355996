export enum KeyTermTypes  {  
    BOOLEAN = "boolean",
    PERCENTAGE = "percentage",
    CURRENCY = "currency",
    DATE = "date",
    TIME = "time",
    DAY_COUNT = "day_count",
    FREE_TEXT = "free_text"
}
export enum KeyTermStatus {
    ENABLED = "enabled",
    DISABLED = "disabled"
}
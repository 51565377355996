import React, { FC, useRef } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { SearchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
//@ts-ignore
// import PDF from "../../assets/sample.pdf";
import "./document.scss";
import { HighlightArea, HighlightPlugin } from "@react-pdf-viewer/highlight";
import { Note } from "./PDFPin";

// Import styles
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry").default;

interface DocumentProps {
  highlightPluginInstance: HighlightPlugin;
  jumpToHighlightArea: (area: HighlightArea) => void;
  searchPluginInstance: SearchPlugin;
  notes: Note[];
  documentURL: string;
  onDocumentLoadSuccess?: (value:boolean) => void;
}

const Document: FC<DocumentProps> = (props) => {
  const { searchPluginInstance, highlightPluginInstance, documentURL ,onDocumentLoadSuccess} = props;

  const handleDocumentLoadSuccess=()=> onDocumentLoadSuccess && onDocumentLoadSuccess(true)

  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          flex: "1 1 0",
          overflow: "auto",
        }}
      >
        {documentURL && (
          <Viewer
          onDocumentLoad={handleDocumentLoadSuccess}
            fileUrl={documentURL}
            plugins={[highlightPluginInstance, searchPluginInstance]}
          />
        )}
      </div>
    </div>
  );
};

export default Document;

import { KeyTermTypes } from './../../enums/keyTermsList';
import { AgreementKeyTerm } from './agreementKeyTerms.model';

import { serializable, alias, object, list, primitive } from 'serializr';

export class KeyTerms {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('key_term_id', primitive()))
	keyTermId?: number;

	@serializable(alias('name', primitive()))
	name?: string;
	@serializable(alias('toggleable', primitive()))
	toggleable?: boolean;

	@serializable(alias('key_term_type', primitive()))
	keyTermType?: KeyTermTypes;

	@serializable(alias('agreement_key_term', list(object(AgreementKeyTerm))))
	agreementKeyTerm: AgreementKeyTerm[] = [];

	@serializable(alias('agreement_amendment_key_term', list(object(AgreementKeyTerm))))
	amendmentKeyTerm: AgreementKeyTerm[] = [];

	@serializable(alias('status', primitive()))
	status?: string;

}
import * as React from "react";
import { Field, ErrorMessage } from "formik";
import { DatePicker, Select } from "antd";
import Error from "../Error";
import moment, { Moment } from "moment";
import "./date-picker.scss";
import {
  PickerPanelBaseProps,
  PickerProps,
} from "antd/lib/calendar/generateCalendar";

interface DatePickerProps {
  name: string;
  placeholder: string;
  value?: string | Moment;
  onChange: (value: Moment | null, dateString: string) => void;
  defaultValue?: any;
  className?: string;
  disabled?: boolean;
  picker?: "time" | "date" | "week" | "month" | "quarter" | "year";
}

const DatePickerComponent: React.FC<DatePickerProps> = (props) => {
  const {
    name,
    onChange,
    placeholder,
    value,
    defaultValue,
    disabled,
    picker,
    className,
  } = props;

  const dateChangeHandler = (value: Moment | null, dateString: string) => {
    onChange(value, dateString);
  };
  return (
    <div className="datepicker">
      {props?.placeholder && props?.value !== undefined && (
        <label htmlFor={props?.name} className="field-label input-label">
          {props?.placeholder}
        </label>
      )}
      <DatePicker
        className={"dateicker-input " + className}
        placeholder={placeholder}
        onChange={dateChangeHandler}
        value={value ? moment(moment(value).format('YYYY-MM-DD')) : undefined}
        defaultValue={defaultValue}
        disabled={disabled}
        picker={picker || "date"}
      />
      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </div>
  );
};

export default DatePickerComponent;

import { Agreement } from "./../../models/Agreements/agreement.model";
import { serialize } from "serializr";
import {
  AgreementDocumentHtml,
  AgreementKeyTerm,
  TaggedPoint,
} from "./../../models/KeyTerms/agreementKeyTerms.model";
import { KeyTerms } from "./../../models/KeyTerms/keyTerms.model";
import {
  KeyTermGroups,
  KeyTermGroupsKeyTerm,
} from "./../../models/KeyTerms/keyTermGroups.model";
import { deserialize } from "serializr";
import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  HF_AGREEMENTS_HTML,
  HF_AGREEMENT_KEY_TERM,
  HF_AGREEMENT_KEY_TERM_REQUEST,
  HL_AGREEMENTS_HTML,
  HL_AGREEMENT_KEY_TERM,
  HL_AGREEMENT_KEY_TERM_CREATE,
  HL_AGREEMENT_KEY_TERM_DISCARD,
  HL_AGREEMENT_KEY_TERM_GROUPS,
  HL_AGREEMENT_KEY_TERM_IMPORT,
  HL_AGREEMENT_KEY_TERM_MARK_COMPLETE,
  STUB_BASE_URL,
} from "./../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Note } from "../../views/Document/PDFPin";
import { HighlightArea } from "@react-pdf-viewer/highlight";

export interface KeyTermList {
  [key: string]: KeyTerms[] | undefined;
}

const AgreementKeyTermService = (
  setKeyTermGroups?: (keyTermGroups?: KeyTermGroups[] | undefined) => void,
  setKeyTerms?: (keyTerms: AgreementKeyTerm[]) => void,
  setKeyTermList?: (keyTermList?: KeyTermList | undefined) => void
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [htmlContentLoading, setHtmlContentLoading] = useState<boolean>(false);
  const [markCompleteLoading, setMarkCompleteLoading] =
    useState<boolean>(false);
  const [htmlContent, setHtmlContent] = useState<AgreementDocumentHtml[]>([]);

  const [keyTermNotes, setKeyTermNotes] = useState<Note[]>([]);

  const requestAgreementKeyTerms = async (agreementId: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        HF_AGREEMENT_KEY_TERM_REQUEST.replace(":agreementId", agreementId)
      );
      Notification({
        message: "Hedge Fund",
        description: "Key terms requested successfully",
        type: NotificationTypes.SUCCESS,
      });
      return new Error();
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Key Terms request failed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchAgreementKeyTermGroups = async (
    agreementId: string,
    isAdmin: boolean,
    amendmentId?: string
  ) => {
    setKeyTermGroups && setKeyTermGroups([]);
    setLoading(true);

    try {
      let API_URL = HL_AGREEMENT_KEY_TERM_GROUPS;
      if (!isAdmin) {
        API_URL = API_URL.replace("hl", "hf");
      }
      if (amendmentId) {
        API_URL += `?amendment_id=${amendmentId}`;
      }
      const response = await axiosInstance.get(
        API_URL.replace(":agreementId", agreementId)
      );
      let data =
        (deserialize(
          KeyTermGroups,
          response.data["key_term_groups"]
        ) as KeyTermGroups[]) ?? [];

      let keyTermsList: KeyTermList = {};
      let updatedNotes: Note[] = [];

      if (amendmentId) {
        data = data.map((group) => {
          if (group.amendmentKeyTerms) {
            return {
              ...group,
              agreementKeyTerms: group.amendmentKeyTerms.map((keyTerm) => ({
                ...keyTerm,
                agreementKeyTerm: [...(keyTerm.amendmentKeyTerm || [])],
              })),
            };
          }
          return group;
        });
      }

      for (let keyTermGroup of data) {
        keyTermsList = {
          ...keyTermsList,
          [keyTermGroup?.id ?? 0]: keyTermGroup.agreementKeyTerms,
        };

        for (let keyTerm of keyTermGroup?.agreementKeyTerms ?? []) {
          if (
            keyTerm.agreementKeyTerm?.length &&
            keyTerm.agreementKeyTerm[0]?.taggedPoint
          ) {
            let note: Note = {
              content: keyTerm.agreementKeyTerm[0]?.referencePoint ?? "",
              highlightAreas: [
                keyTerm.agreementKeyTerm[0]?.taggedPoint as HighlightArea,
              ],
              id: keyTerm.agreementKeyTerm[0]?.id ?? 0,
              quote: "",
            };
            if(amendmentId || (agreementId && !keyTerm.agreementKeyTerm[0]?.amendmentId))
            updatedNotes.push(note);
          }
        }
      }

      setKeyTermNotes(updatedNotes);
      setKeyTermGroups && setKeyTermGroups(data);
      setKeyTermList && setKeyTermList(keyTermsList);
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Key Term Groups fetching failed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateKeyTermNotes = (keyTerms: KeyTermGroupsKeyTerm[]) => {
    for (let keyTerm of keyTerms) {
      if (
        keyTerm.agreementKeyTerm?.length &&
        keyTerm.agreementKeyTerm[0]?.taggedPoint
      ) {
        let note: Note = {
          content: keyTerm.agreementKeyTerm[0]?.referencePoint ?? "",
          highlightAreas: [
            keyTerm.agreementKeyTerm[0]?.taggedPoint as HighlightArea,
          ],
          id: keyTerm.agreementKeyTerm[0]?.id ?? 0,
          quote: "",
        };

        setKeyTermNotes((keyTermNotes) => [...keyTermNotes, note]);
      }
    }
  };

  const fetchAgreementKeyTerms = async (
    agreementId: string,
    params: {
      key_term_group_id: string;
    }
  ) => {
    if (!agreementId) return;
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        HL_AGREEMENT_KEY_TERM.replace(":agreementId", agreementId),
        { params }
      );
      const keyTerms = deserialize(
        KeyTerms,
        response.data["agreement_key_terms"] as any[]
      );
      // updateKeyTermNotes(keyTerms);
      setKeyTerms && setKeyTerms(keyTerms);
      return keyTerms;
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Key Terms fetching failed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchAgreementHFKeyTerms = async (
    agreementId: string,
    params: {
      key_term_group_id: string;
    }
  ) => {
    setLoading(true);
    setKeyTerms && setKeyTerms([]);
    try {
      const response = await axiosInstance.get(
        HF_AGREEMENT_KEY_TERM.replace(":agreementId", agreementId),
        { params }
      );
      const data = deserialize(
        KeyTerms,
        response.data["agreement_key_terms"] as any[]
      );
      setKeyTerms && setKeyTerms(data);
      return data;
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Key Terms fetching failed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const importKeyTermsFromAgreement = async (
    agreementId: string,
    params: {
      import_from: string;
    }
  ) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        HL_AGREEMENT_KEY_TERM_IMPORT.replace(":agreementId", agreementId),
        { params }
      );
      // const data = deserialize(KeyTerms, response.data['agreement_key_terms']) as Agreement[]
      // setKeyTerms(data)
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Unable to import key terms",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createAgreementKeyTerms = async (
    values: AgreementKeyTerm,
    agreementId: string,
    activeTabId: string
  ) => {
    setLoading(true);
    try {
      const serializedData = serialize(AgreementKeyTerm, values);
      const payload = {
        [activeTabId === "1"
          ? "agreement_key_term"
          : "agreement_amendment_key_term"]: serializedData,
      };
      const url = HL_AGREEMENT_KEY_TERM_CREATE.replace(
        ":agreementId",
        agreementId
      );
      const { data } = await axiosInstance.post(
        activeTabId === "1"
          ? url + "/agreement_key_terms"
          : url +
              "/amendments/" +
              activeTabId +
              "/agreement_amendment_key_terms",
        payload
      );
      const keyTerm = deserialize(
        AgreementKeyTerm,
        data[
          activeTabId === "1"
            ? "agreement_key_term"
            : "agreement_amendment_key_term"
        ]
      );

      return keyTerm;
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Key Terms creation failed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateAgreementKeyTerms = async (
    values: AgreementKeyTerm,
    activeTabId: string,
    agreementId: string,
    type?: "removePin"
  ) => {
    setLoading(true);
    try {
      const serializedData = serialize(AgreementKeyTerm, values);
      const payload = {
        [activeTabId === "1"
          ? "agreement_key_term"
          : "agreement_amendment_key_term"]: type
          ? {
              tagged_point: null,
            }
          : serializedData,
      };
      let url = HL_AGREEMENT_KEY_TERM_CREATE.replace(
        ":agreementId",
        agreementId
      );
      const { data } = await axiosInstance.put(
        activeTabId === "1"
          ? url + "/agreement_key_terms" + "/" + values?.id
          : url +
              "/amendments/" +
              activeTabId +
              "/agreement_amendment_key_terms/" +
              values?.id,
        payload
      );
      const keyTerm = deserialize(
        AgreementKeyTerm,
        data[
          activeTabId === "1"
            ? "agreement_key_term"
            : "agreement_amendment_key_term"
        ]
      );
      return keyTerm;
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Key Terms update failed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateAgreementKeyTermsCompleted = async (agreementId: string) => {
    setMarkCompleteLoading(true);
    try {
      const response = await axiosInstance.get(
        HL_AGREEMENT_KEY_TERM_MARK_COMPLETE.replace(":agreementId", agreementId)
      );

      Notification({
        message: "Hedge Fund",
        description: "Agreement marked as completed",
        type: NotificationTypes.SUCCESS,
      });
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Unable to mark agreement as completed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setMarkCompleteLoading(false);
    }
  };

  const deleteAgreementKeyTerms = async (agreementId: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(
        HL_AGREEMENT_KEY_TERM_DISCARD.replace(":agreementId", agreementId)
      );
      // Notification({
      //   message: "Hedge Fund",
      //   description: "Key Terms discarded successfully",
      //   type: NotificationTypes.SUCCESS,
      // });
      return true;
    } catch (error) {
      Notification({
        message: "Hedge Fund",
        description: "Key Terms discard failed",
        type: NotificationTypes.ERROR,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    keyTermNotes,
    loading,
    htmlContent,
    htmlContentLoading,
    markCompleteLoading,
    setKeyTermList,
    setKeyTerms,
    fetchAgreementKeyTerms,
    deleteAgreementKeyTerms,
    createAgreementKeyTerms,
    updateAgreementKeyTerms,
    requestAgreementKeyTerms,
    fetchAgreementHFKeyTerms,
    fetchAgreementKeyTermGroups,
    importKeyTermsFromAgreement,
    updateAgreementKeyTermsCompleted,
  };
};
export default AgreementKeyTermService;

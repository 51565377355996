import Modal from "antd/lib/modal/Modal";
import * as React from "react";
import { FC, ReactElement } from "react";
import ButtonComponent from "../ButtonComponent";
import "./uiModal.scss";
interface ControllerProps {
  text: string;
  type?: "submit" | "button";
  clickHandler: () => void;
  disabled?: boolean
  loading?: boolean
}
interface UIModalProps {
  visible: boolean;
  title?: string;
  closeModal: () => void;
  children: ReactElement;
  submitProps?: ControllerProps;
  cancelProps?: ControllerProps;
  onClosed?: () => void;
  noControl?: boolean;
  className?: string
}
export const UIModal: FC<UIModalProps> = ({
  title,
  visible,
  closeModal,
  children,
  submitProps,
  cancelProps,
  noControl,
  onClosed,
  className
}) => {
  return (
    <Modal
      className={`ui-modal ${className}`}
      title={title}
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      centered
      afterClose={closeModal}
      footer={null}
    >
      {children}
      {noControl ? null : (
        <div className="ui-modal__controllers">
          <ButtonComponent onClick={cancelProps?.clickHandler} type="ghost">
            {cancelProps?.text}
          </ButtonComponent>
          <ButtonComponent
            htmlType={submitProps?.type ?? "button"}
            onClick={submitProps?.clickHandler}
            type="primary"
            disabled={submitProps?.disabled}
            loading={submitProps?.loading}
          >
            {submitProps?.text}
          </ButtonComponent>
        </div>
      )}
    </Modal>
  );
};

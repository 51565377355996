import Modal from "antd/lib/modal/Modal";
import React, { FC } from "react";
import ButtonComponent from "../ButtonComponent";
import "./confirmationAlert.scss";
// import DeleteIcon from "../../../assets/icons/deleteWhite.svg";
// import ApproveIcon from "../../../assets/icons/approveWhite.svg";
// import CloseIcon from "../../../assets/icons/close.svg";

interface ConfirmationAlertProps {
  type: "delete" | "success" | "reject";
  visible: boolean;
  title: string;
  description?: string;
  logo?: string;
  deleteHandler?: () => void;
  cancelHandler?: () => void;
  undoHandler?: () => void;
  closeHandler: () => void;
  customButtons?: React.ReactNode
  loading?: boolean
}
export const ConfirmationAlert: FC<ConfirmationAlertProps> = ({
  visible,
  type,
  title,
  logo,
  description,
  deleteHandler,
  cancelHandler,
  undoHandler,
  closeHandler,
  customButtons,
  loading,
}) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      title={null}
      className={
        type == "delete"
          ? "delete-alert__container"
          : type == "reject"
            ? "reject-alert__container"
            : "confirmation-alert__container"
      }
      onCancel={closeHandler}
      destroyOnClose
    >
      {type == "delete" ? (
        <>
          <div className="delete-alert__icon">
            {/* <img src={DeleteIcon} /> */}
          </div>
          <div>
            <p className="delete-alert__title">{title}</p>
          </div>
          {logo && <div className="delete-alert__logo">
            <img src={logo} />
          </div>}
          <div className="delete-alert__description">
            <p>{description}</p>
          </div>
          <div className="delete-alert__controllers">
            <ButtonComponent onClick={cancelHandler} type="ghost">
              Cancel
            </ButtonComponent>
            <ButtonComponent loading={loading} onClick={deleteHandler} danger={true}>
              Delete
            </ButtonComponent>
          </div>
        </>
      ) : type == "reject" ? (
        <>
          <div className="reject-alert__icon">
            {/* <img src={CloseIcon} /> */}
          </div>
          <div>
            <p className="reject-alert__title">{title}</p>
          </div>
          <div className="reject-alert__logo">
            <img src={logo} />
          </div>
          <div className="reject-alert__description">
            <p>{description}</p>
          </div>
          <div className="reject-alert__controllers">
            <ButtonComponent onClick={undoHandler} type="ghost">
              Undo Action
            </ButtonComponent>
          </div>
        </>
      ) : (
        <>
          <div className="confirmation-alert__icon">
            {/* <img src={ApproveIcon} /> */}
          </div>
          <div>
            <p className="confirmation-alert__title">{title}</p>
          </div>
          {logo && <div className="confirmation-alert__logo">
            <img src={logo} />
          </div>}
          <div className="confirmation-alert__description">
            <p>{description}</p>
          </div>
          <div className="confirmation-alert__controllers">{
            customButtons ?
              customButtons :
              <ButtonComponent onClick={undoHandler} type="ghost">
                Undo Action
              </ButtonComponent>
          }
          </div>
        </>
      )}
    </Modal>
  );
};

import { serializable, alias, primitive, object } from 'serializr';

export class TaggedPoint {
	@serializable(alias('height', primitive()))
	height?: number;

	@serializable(alias('width', primitive()))
	width?: number;

	@serializable(alias('left', primitive()))
	left?: number;

	@serializable(alias('top', primitive()))
	top?: number;

	@serializable(alias('page_index', primitive()))
	pageIndex?: number;
}

export class AgreementKeyTerm {

	@serializable(alias('key_term_id', primitive()))
	keyTermId?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('agreement_id', primitive()))
	agreementId?: number;
	
	@serializable(alias('amendment_name', primitive()))
	amendmentName?: string;
	@serializable(alias('amendment_id', primitive()))
	amendmentId?: number;

	@serializable(alias('value', primitive()))
	value?: string;

	// @serializable(alias('key_term', object(KeyTerms)))
	// keyTerm?: KeyTerms;

	@serializable(alias('is_present', primitive()))
	isPresent?: string;

	@serializable(alias('reference_point', primitive()))
	referencePoint?: string;

	@serializable(alias('tagged_point', object(TaggedPoint)))
	taggedPoint?: TaggedPoint;

	@serializable(alias('is_amendment', primitive()))
	isAmendment?: boolean = false;
	
	@serializable(alias('outlier', primitive()))
	outlier?: boolean;
}

export class AgreementDocumentHtml {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('next_html_content_id', primitive()))
	next?: number;

	@serializable(alias('html', primitive()))
	html?: string;
}
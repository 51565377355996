import { Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import "./searchInput.scss"
interface SearchInputProps {
    onSearch: (value: string) => void
}
export const SearchInput: FC<SearchInputProps> = ({ onSearch }) => {

    const [search, setSearch] = useState<string>('')

    useEffect(() => {
        const timer = setTimeout(() => {
            onSearch(search)
        }, 800);
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [search])

    return (
        <Input className="search-input" onChange={(e) => setSearch(e.target.value)} />
    )
}
import React, { Fragment, useEffect } from "react";
import {
  HighlightArea,
  highlightPlugin,
  MessageIcon,
  RenderHighlightContentProps,
  RenderHighlightsProps,
  RenderHighlightTargetProps,
  Trigger,
} from "@react-pdf-viewer/highlight";
//@ts-ignore
import Pin from "../../../assets/pin indicator.svg";
import Modal from "antd/lib/modal/Modal";
import { KeyTerms } from "../../../models/KeyTerms/keyTerms.model";
import AgreementKeyTermService, {
  KeyTermList,
} from "../../../services/AgreementService/agreementKeyTerm.service";
import { message } from "antd";
import { KeyTermGroups } from "../../../models/KeyTerms/keyTermGroups.model";

export interface Note {
  id: number;
  content: string;
  highlightAreas: HighlightArea[];
  quote: string;
}

const usePDFPin = (
  notes: Note[],
  openAddTag: boolean,
  closeAddTag: () => void,
  keyTermNote: Note[],
  goToSelectedView: (noteId: number) => void,
  keyTermList?: KeyTermList,
  setKeyTermList?: (keyTermList?: KeyTermList) => void,
  keyTerm?: KeyTerms,
  setNotes?: (notes: Note[]) => void,
  keyTermGroups?: KeyTermGroups[],
  setKeyTermGroups?: (keyTermGroups?: KeyTermGroups[]) => void,
  activeTabId: string = ""
) => {
  const { updateAgreementKeyTerms } = AgreementKeyTermService();

  const params = new URLSearchParams(window.location.search);

  let noteId = notes.length;
  let timer: any = undefined;
  let selectTime: any = undefined;

  const noteEles: Map<number, HTMLElement> = new Map();

  useEffect(() => {
    setNotes && setNotes(keyTermNote);
  }, [keyTermNote]);

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
    <div
      style={{
        background: "#eee",
        display: "flex",
        position: "absolute",
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: "translate(0, 8px)",
      }}
    >
      {(() => {
        clearTimeout(selectTime);
        selectTime = setTimeout(() => {
          props.toggle();
        }, 100);
        return <Fragment />;
      })()}
    </div>
  );

  const addNote = async (props: RenderHighlightContentProps) => {
    const closeLoader = message.loading("Adding pin", 0);
    if (props.highlightAreas?.length && keyTerm) {
      const updatedKeyTerms = await updateAgreementKeyTerms(
        {
          id: keyTerm.agreementKeyTerm[0].id,
          taggedPoint: {
            ...props.highlightAreas[0],
          },
        },
        activeTabId,
        params.get("agreement") ?? ""
      );
      if (keyTermList && setKeyTermList && setKeyTermGroups) {
        let newKeyTermList: { [key: number]: KeyTerms[] } = {};
        for (let [key, keyTerms] of Object.entries(keyTermList)) {
          if (keyTerms) {
            newKeyTermList = {
              ...newKeyTermList,
              [key]: keyTerms?.map((keyTerm) => ({
                ...keyTerm,
                agreementKeyTerm: keyTerm.agreementKeyTerm.length
                  ? keyTerm.agreementKeyTerm[0].id === updatedKeyTerms.id
                    ? [
                        {
                          ...keyTerm.agreementKeyTerm[0],
                          taggedPoint: updatedKeyTerms.taggedPoint,
                        },
                      ]
                    : keyTerm.agreementKeyTerm
                  : [],
              })),
            };
          }
        }
        const updatedKeyTermGroups = keyTermGroups?.map((group) => {
          if (group.id !== undefined && group.id in newKeyTermList) {
            group.agreementKeyTerms = newKeyTermList[group.id];
          }
          return group;
        });
        setKeyTermList(newKeyTermList);
        setKeyTermGroups(updatedKeyTermGroups);
      }

      closeLoader();
      const note: Note = {
        content: updatedKeyTerms.referencePoint ?? "",
        highlightAreas: [updatedKeyTerms.taggedPoint as HighlightArea],
        id: updatedKeyTerms?.id ?? 0,
        quote: "",
      };

      if (updatedKeyTerms) {
        setNotes && setNotes(notes.concat([note]));
        props.cancel();
      }
    }
  };

  const renderHighlightContent = (props: RenderHighlightContentProps) => {
    return (
      <Modal visible={openAddTag} className="pin-modal-fake">
        {(() => {
          clearTimeout(timer);
          if (openAddTag) {
            timer = setTimeout(() => {
              closeAddTag();
              addNote(props);
            }, 50);
          }
          return <Fragment />;
        })()}
      </Modal>
    );
  };

  const jumpToNote = (note: Note) => {
    // if (noteEles.has(note.id)) {
    // noteEles.get(note.id)?.scrollIntoView();
    // }
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {notes.map((note) => {
        return (
          <React.Fragment key={note.id}>
            {note.highlightAreas
              .filter((area) => area.pageIndex === props.pageIndex)
              .map((area, idx) => {
                return (
                  <div
                    key={idx}
                    style={Object.assign(
                      {},
                      {
                        // background: "yellow",
                        // opacity: 0.4,
                        zIndex:1
                      },
                      props.getCssProperties(area, props.rotation)
                    )}
                    onClick={() => jumpToNote(note)}
                    ref={(ref): void => {
                      noteEles.set(note.id, ref as HTMLElement);
                    }}
                  >
                    <img
                      style={{
                        transform: "scaleX(-1)",
                        marginTop: "-16px",
                        marginLeft: "-13px",
                        cursor: "pointer",                 
                      }}
                      className={`${note?.id}-img`}
                      src={Pin}
                      alt="pin"
                      width={18}
                      height={18}
                      onClick={() => goToSelectedView(note.id)}
                    />
                  </div>
                );
              })}
          </React.Fragment>
        );
      })}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  return {
    jumpToHighlightArea,
    highlightPluginInstance,
    notes,
  };
};

export default usePDFPin;

import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authReducer";
import pdfReducer, { PDFState } from "../reducers/pdfReducer";
export interface RootReducerProps {
    auth: AuthState;
    pdf: PDFState;
}

const RootReducer = combineReducers<RootReducerProps>({
    auth: authReducer,
    pdf: pdfReducer,
});

export default RootReducer;
import { Agreement } from "../../models/Agreements/agreement.model";
import { AgreementKeyTerm } from "../../models/KeyTerms/agreementKeyTerms.model";
import { KeyTermGroups } from "../../models/KeyTerms/keyTermGroups.model";
import { KeyTermList } from "../../services/AgreementService/agreementKeyTerm.service";
import { Note } from "../../views/Document/PDFPin";
import {
  SET_AGREEMENT,
  SET_KEY_TERM_ITEMS,
  SET_KEY_TERM_GROUP,
  SET_AGREEMENT_KEY_TERMS,
  SET_NOTES,
} from "../definitions/pdfConstants";
import { PDFAction } from "../reducers/pdfReducer";

export const setKeyTermGroups = (keyTermGroups: KeyTermGroups[]): PDFAction => {
  return {
    type: SET_KEY_TERM_GROUP,
    payload: {
      keyTermGroups,
    },
  };
};

export const setAgreement = (agreement: Agreement): PDFAction => {
  return {
    type: SET_AGREEMENT,
    payload: {
      agreement,
    },
  };
};

export const setKeyTermList = (keyTermList: KeyTermList): PDFAction => {
  return {
    type: SET_KEY_TERM_ITEMS,
    payload: {
      keyTermList,
    },
  };
};

export const setKeyTerms = (keyTerms: AgreementKeyTerm[]): PDFAction => {
  return {
    type: SET_AGREEMENT_KEY_TERMS,
    payload: {
      keyTerms,
    },
  };
};

export const setNotes = (notes: Note[]): PDFAction => {
  return {
    type: SET_NOTES,
    payload: {
      notes,
    },
  };
};

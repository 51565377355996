import React from "react";
import { Field, ErrorMessage } from "formik";
import { Select } from "antd";
import Error from "../Error";
import "./dropdownField.scss";
import { SelectValue } from "antd/lib/select";

interface DropdownFieldProps {
  name: string;
  placeholder: string;
  mode: "single" | "tags" | "multiple";
  options: any;
  showSearch: boolean;
  value: any;
  onChange: (value: SelectValue) => void;
  optionFilterProp?: string;
  defaultValue?: any;
  className?: string;
  disabled?: boolean;
  showLabel?: boolean;
  setFieldTouched?: Function;
  onSelect?: Function;
  allowClear?:boolean
}

const DropdownField: React.FC<DropdownFieldProps> = (props) => {
  const { name, setFieldTouched = () => {}, showLabel = true ,allowClear=false} = props;

  return (
    <div className="dropdown__wrapper">
      <div
        className="dropdown-field"
        onClick={() => {
          setFieldTouched();
        }}
      >
        {props?.placeholder && showLabel && props?.value !== undefined && (
          <label htmlFor={props?.name} className="field-label">
            {props?.placeholder}
          </label>
        )}
        <Field as={Select} {...props}></Field>
        <ErrorMessage name={name}>
          {(message: string) => <Error message={message} />}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default DropdownField;


import { serializable, alias, object, list, primitive } from 'serializr';
import { AgreementKeyTerm } from './agreementKeyTerms.model';

export class KeyTermGroupsKeyTerm {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('key_term_type', primitive()))
	keyTermType?: string;
	
	@serializable(alias('toggleable', primitive()))
	toggleable?: boolean;

	@serializable(alias('agreement_key_term', list(object(AgreementKeyTerm))))
	agreementKeyTerm: AgreementKeyTerm[] = [];

	@serializable(alias('agreement_amendment_key_term', list(object(AgreementKeyTerm))))
	amendmentKeyTerm ?: AgreementKeyTerm[];

}

export class KeyTermGroups {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('agreement_key_terms', list(object(KeyTermGroupsKeyTerm))))
	agreementKeyTerms?: KeyTermGroupsKeyTerm[];

	@serializable(alias('agreement_amendment_key_terms', list(object(KeyTermGroupsKeyTerm))))
	amendmentKeyTerms?: KeyTermGroupsKeyTerm[];

}